import { Box, TextField, Typography } from "@mui/material";
import React from "react";

export default function SideLabeledInput({
  label,
  value,
  name,
  onChange,
  disabled,
  type,
  autoComplete,
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: 600,
          color: "primary.oldMain",
          pl: 2,
        }}
      >
        {" "}
        {label} {" : "}
      </Typography>

      <TextField
        id="outlined-basic"
        variant="outlined"
        hiddenlabel="true"
        size="small"
        disabled={disabled ? true : false}
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        autoComplete={autoComplete}
      />
    </Box>
  );
}

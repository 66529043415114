import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  Tooltip,
  Typography,
} from "@mui/material";

import UserDataForm from "./UserDataForm";
import LabeledSelect from "../../../Components/inputs/LabeledSelect";
import {
  departmentsOptions,
  problemsOptions,
} from "../../../Components/CategoryManager/utils";
import FileInput from "../../../Components/inputs/FileInput";
import { problemsComponentsArray } from "../../../Components/ProblemTypes/Components/ProblemsArray";
import axios from "../../../api/axios";
import TextInput from "../../../Components/inputs/TextInput";
import { levels } from "./utils";
import { AppContext } from "../../../Context/App.context";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  initialSubjects,
  levelSelectionPlaceholder,
  problemSelectionPlaceholder,
} from "../utils";
import { handleSubmitForm } from "./handleSubmitForm";

const levelTypes = levels.map((l) => l.label);

export const problemTypes = problemsOptions
  .map((problem) => problem.label)
  .slice(1);

export default function StudentForm({ problemData, setProblemData }) {
  const { courses, setCourses } = React.useContext(AppContext);

  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [resError, setResError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onChangeSubject = (e, id) => {
    const { name, value } = e.target;
    setError("");
    setSuccess("");

    let more = { name: "", number: "" };

    name === "name"
      ? (more = {
          number: courses.find((c) => c.subjectName === value)?.subjectNumber,
        })
      : name === "number"
      ? (more = {
          name: courses.find((c) => c.subjectNumber === value)?.subjectName,
        })
      : (more = {});

    // console.log({ name, more, courses, id });

    const newSub = (s) => {
      const res = { ...s, [name]: value, ...more };
      // console.log({ res });
      return res;
    };

    setProblemData({
      ...problemData,
      subjects: problemData.subjects.map((s) => {
        if (s.id === id) {
          return newSub(s);
        } else {
          return s;
        }
      }),
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setSuccess("");

    setProblemData({ ...problemData, [name]: value });
  };

  const onProblemChange = (e) => {
    const { value } = e.target;
    setError("");
    setSuccess("");
    setProblemData({ ...problemData, problemType: value });
  };

  const onLevelChange = async (e) => {
    const { value } = e.target;
    setError("");
    setSuccess("");
    setProblemData({ ...problemData, level: value });
  };

  React.useEffect(() => {
    setProblemData({
      ...problemData,
      problemType: problemSelectionPlaceholder,
      subjects: initialSubjects,
    });
    const func = async () => {
      try {
        const res = await axios.get(
          `course?department=${problemData.department}&level=${
            levels.find((l) => l.label === problemData.level).number
          }`
        );
        // console.log({ res });
        // setProblemData({ ...problemData, subjects: [] });
        setCourses(res?.data || []);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, [problemData.level, problemData.department]);

  const onChangeConstraints = (e, c) => {
    setError("");
    setSuccess("");

    if (e.target.checked) {
      setProblemData({
        ...problemData,
        constraints: [...problemData.constraints, c],
      });
    } else {
      const constraints = problemData.constraints.filter((co) => co !== c);

      setProblemData({ ...problemData, constraints });
    }
  };

  const onSelectFile = (e, name) => {
    const { files } = e.target;
    setError("");
    setSuccess("");

    console.log({ files: files[0] });
    setProblemData({ ...problemData, [name]: files[0] });
  };

  const onIsCorrectData = (e) => {
    setProblemData({ ...problemData, isCorrectData: e.target.checked });
  };

  const onSubmitProblem = async () => {
    handleSubmitForm({
      problemData,
      setProblemData,
      error,
      setError,
      setSuccess,
      setResError,
      setLoading,
    });
  };
  return (
    <Box sx={{ width: { xs: "100%", lg: "80%" } }}>
      <UserDataForm />

      {problemData.department === "آخرى" && (
        <TextInput
          onChange={onChange}
          label="اسم التخصص الآخر"
          name="otherDepartmentName"
          value={problemData.otherDepartmentName}
        />
      )}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <LabeledSelect
            label="تحديد المستوى"
            options={levelTypes}
            placeholder={levelSelectionPlaceholder}
            value={problemData.level}
            onChange={onLevelChange}
          />
        </Box>
        <Box sx={{ p: 3 }}>
          <Tooltip title="أضغط لمعرفة مقررات المستويات" placement="top">
            <a
              href={
                departmentsOptions.find(
                  (d) => d.label === problemData.department
                ).link
              }
              rel="noreferrer"
              target="_blank"
              style={{ color: "#777" }}
            >
              <FilePresentIcon sx={{ transform: "translateY(8px)" }} />
            </a>
          </Tooltip>
        </Box>
      </Box>

      <LabeledSelect
        label="المشكلة"
        options={problemTypes}
        placeholder={problemSelectionPlaceholder}
        value={problemData.problemType}
        onChange={onProblemChange}
      />

      {
        problemsComponentsArray({
          state: { ...problemData },
          onChange,
          onChangeSubject,
          subjectsList: problemData.subjects,
          onChangeConstraints,
          onSelectFile,
        }).find((p) => p.type === problemData.problemType)?.form
      }

      <Grid2 container>
        <Grid2 size={6}>
          <FileInput
            label="إرفاق الجدول الدراسى"
            name="scheduleFile"
            value={problemData.scheduleFile?.name}
            onSelectFile={onSelectFile}
          />
        </Grid2>
        <Grid2 size={6}>
          <FileInput
            label="إرفاق السجل الأكاديمى"
            name="academicFile"
            value={problemData.academicFile?.name}
            onSelectFile={onSelectFile}
          />
        </Grid2>
      </Grid2>

      <Typography sx={{ color: "red", textAlign: "center" }}>
        {error}
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={problemData.isCorrectData}
            onChange={onIsCorrectData}
          />
        }
        label="أقر بصحة البيانات المدخلة"
        sx={{ color: "primary.main" }}
      />

      {/* <Button
        onClick={onSubmitProblem}
        variant="contained"
        fullWidth
        sx={{ fontSize: "1.2rem" }}
        disabled={
          !problemData.isCorrectData ||
          error ||
          problemData.department !== departmentsOptions[0].label
        }
      >
        أضف الشكوى
      </Button> */}
      <LoadingButton
        onClick={onSubmitProblem}
        variant="contained"
        loading={loading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        fullWidth
        sx={{ fontSize: "1.2rem" }}
        disabled={
          !problemData.isCorrectData ||
          error ||
          problemData.department !== departmentsOptions[0].label
        }
      >
        <span style={{ paddingRight: "10px" }}>أضف الشكوى</span>
      </LoadingButton>
      {problemData.department !== departmentsOptions[0].label && (
        <Typography sx={{ color: "#999", fontSize: "15px" }}>
          هذة الخدمة مُفعلة فقط لتخصص ({departmentsOptions[0].label}) فى الوقت
          الحالى.
        </Typography>
      )}
      <Typography sx={{ color: "primary.main", textAlign: "center" }}>
        {success}
      </Typography>
      <Typography sx={{ color: "red", textAlign: "center" }}>
        {resError}
      </Typography>
    </Box>
  );
}

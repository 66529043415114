import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { columns } from "./cols";
import { mainTableAttr } from "../../../Components/mainTableAttr";
import { Box, Button } from "@mui/material";
import SubDetails from "./SubDetails";

import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getExportedData } from "./exportedData";

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
});

const AdminTable = ({ data, setData }) => {
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const exportedData = getExportedData(rowData);
    const csv = generateCsv(csvConfig)(exportedData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const exportedData = getExportedData(data);
    // console.log({ row: data[0] });
    const csv = generateCsv(csvConfig)(exportedData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    ...mainTableAttr,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",

    enableExpanding: true,
    renderDetailPanel: ({ row }) =>
      row.original.problemType ? (
        <SubDetails row={row.original} setData={setData} data={data} />
      ) : null,
    // enableExpandAll: false, //hide expand all button in header

    displayColumnDefOptions: {
      "mrt-row-expand": {
        enableResizing: true, //allow resizing
        size: 20, //make the expand column wider
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          sx={{ pr: 1 }}
        >
          <span style={{ marginRight: "10px" }}>تحميل كل البيانات</span>
        </Button>

        <Button
          variant="contained"
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          <span style={{ marginRight: "10px" }}>تحميل ما تم اختياره فقط</span>
        </Button>

        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          تحميل كل الصفوف
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          تحميل هذه الصفحة فقط
        </Button>*/}
      </Box>
    ),
  });

  return (
    <Box sx={{ boxShadow: "0 0 2px #777" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default AdminTable;

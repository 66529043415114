import { Box, Divider, Grid2, TextField, Typography } from "@mui/material";
import React from "react";
import { AppContext } from "../../../../Context/App.context";
import LabeledSelect from "../../../inputs/LabeledSelect";

export const subjectsInputs = [
  {
    label: "اسم المقرر",
    field: "name",
    placeholder: "--تحديد اسم المقرر--",
  },
  {
    label: "رقم المقرر",
    field: "number",
    placeholder: "--تحديد رقم المقرر--",
  },
  {
    label: "CRN",
    field: "crn",
  },
  {
    label: "رمز الشعبة",
    field: "sectionNumber",
  },
];

export default function SubjectRow({
  index,
  subject,
  disabled,
  onChangeSubject,
  state,
}) {
  const { courses } = React.useContext(AppContext);

  return (
    <Grid2 container spacing={2} alignItems="center" sx={{ mt: 2 }}>
      {subjectsInputs.map((s) => {
        return (
          <Grid2
            size={{
              xs: s.field === "name" ? 8 : s.field === "number" ? 4 : 6,
              md: s.field === "name" ? 4 : s.field === "number" ? 2 : 3,
            }}
            key={s.label}
          >
            {state?.department !== "آخرى" && s.field === "name" ? (
              <LabeledSelect
                label={s.label + (index + 1)}
                name={s.field}
                onChange={(e) => onChangeSubject(e, index + 1)}
                value={subject?.[s.field]}
                disabled={disabled}
                options={courses.map((c) =>
                  s.field === "name" ? c.subjectName : c.subjectNumber
                )}
                placeholder={s.placeholder}
                size="small"
              />
            ) : (
              <>
                <Typography
                  sx={{
                    fontWeight: 600,
                    transform: "translateY(-4px)",
                    color: "primary.main",
                  }}
                >
                  {s.label} {index + 1}:
                </Typography>
                <TextField
                  name={s.field}
                  value={subject?.[s.field]}
                  onChange={(e) => onChangeSubject(e, index + 1)}
                  disabled={
                    state?.department !== "آخرى" && s.field === "number"
                      ? true
                      : disabled
                  }
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ transform: "translateY(-4px)" }}
                />
              </>
            )}
          </Grid2>
        );
      })}
      <Divider />
    </Grid2>
  );
}

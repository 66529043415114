import { Box, Button, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/axios";
import { AppContext } from "../../Context/App.context";

export default function RedirectRegistration() {
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const { token } = useParams();

  const { setUser } = React.useContext(AppContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    const func = async () => {
      localStorage.setItem("token", token);
      setUser({ role: "visitor" });

      const res = await api
        .post("/auth/confirm-registration")
        .then((res) => {
          console.log({ res });
          if (res.status === 201) {
            setMessage(res?.data?.message);
            localStorage.removeItem("token");
            setUser({ role: "visitor" });
          }
        })
        .catch((err) => {
          console.log(err);

          setError(" حدث خطأ ما.. الرجاء إعادة المحاولة");
        });
    };
    func();
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 5, mt: 10, textAlign: "center", mx: { md: 7, xs: 2 } }}>
        <h1 style={{ textAlign: "center", color: "#333" }}>
          مرحبا بك فى منصة الشكاوى
        </h1>
        {message ? (
          <>
            <p style={{ color: "green", textAlign: "center" }}>{message}</p>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
            >
              الدخول لحسابى
            </Button>
          </>
        ) : error ? (
          <>
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
            >
              المحاولة مجددا
            </Button>
          </>
        ) : (
          <p style={{ textAlign: "center", color: "#333" }}>
            الرجاء الإنتظار لتفعيل الحساب...
          </p>
        )}
      </Paper>
    </Box>
  );
}

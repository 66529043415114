import { Box } from "@mui/material";
import React from "react";

import AdminTable from "./Table";
import CategoryManager from "../../Components/CategoryManager";
import { departmentsOptions } from "../../Components/CategoryManager/utils";
import { dummyData } from "./Table/dummy";
import axios from "../../api/axios";

export default function AdminPage() {
  const [selected, setSelected] = React.useState({
    department: "",
    problem: "",
    current: "warning",
  });

  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState("");

  const handleSelection = (event) => {
    const { name, value } = event.target;
    setError("");
    const change = {};
    change[name] = value;

    if (name === "department") {
      change["current"] = departmentsOptions.find(
        (option) => option.label === value
      ).department;
    }

    setSelected({ ...selected, ...change });
  };

  const onAddSelection = async () => {
    let query = "department=" + selected.department;

    if (selected.problem !== "الكل") {
      query = query + "&problemType=" + selected.problem;
    }

    try {
      const res = await axios.get("/problem?" + query);

      setData(res.data);
    } catch (error) {
      setError("حدث خطأ ما.. الرجاء تحديث الصفحة.");
      console.log(error);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CategoryManager
            selected={selected}
            handleSelection={handleSelection}
            onAddSelection={onAddSelection}
            error={error}
          />
        </Box>

        <img
          src={"images/" + selected.current + ".png"}
          alt="no selection"
          width="120px"
          height="120px"
        />
      </Box>

      <AdminTable data={data} setData={setData} />
    </Box>
  );
}

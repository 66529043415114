import api from "../../../api/axios";
import { previousProblemsPath } from "../../../routes/studentRoutes";

export const getPreviousProblems = async () => {
  try {
    const res = await api.get("/problem" + previousProblemsPath);
    console.log({ res });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

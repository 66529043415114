import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function LogoAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#eee", color: "#444", px: { xs: 0, md: 2 } }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Button variant="h6" component="div" sx={{ my: 1 }}>
              <img
                src="https://problems.reports.ltd/images/logo.png"
                alt="logo"
                height="60px"
              />
            </Button>
          </Typography>

          <Typography
            variant="h2"
            sx={{
              lineHeight: "23px",
              fontWeight: "800",
              color: "#0a6a79",
              display: { xs: "none", md: "block" },
              fontSize: { xs: "15px", md: "20px" },
            }}
          >
            جامعة الملك فيصل
            <br />
            كلية إدارة الأعمال
            <br />
            الشئون الأكاديمية
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

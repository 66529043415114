export const mainTableAttr = {
  //   enableColumnActions: false,
  enableColumnFilters: false,
  //   enableTopToolbar: false,
  //   enableBottomToolbar: false,
  //   enablePagination: false,
  enableSorting: false,
  //   muiTableBodyRowProps: { hover: false },
  muiTableProps: {
    // sx: {
    //   border: "1px solid rgba(81, 81, 81, .5)",
    //   caption: {
    //     captionSide: "top",
    //   },
    // },
  },
  muiTableHeadCellProps: {
    sx: {
      border: "0.5px solid rgba(50, 50, 50, .2)",
      fontWeight: "600",
      padding: "10px",
      backgroundColor: "primary.main",
      color: "white",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      border: "1px solid rgba(50, 50, 50, .1)",
      padding: "10px",
    },
  },
};

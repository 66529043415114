import {
  departmentsOptions,
  problemsOptions,
} from "../../../Components/CategoryManager/utils";
import { dummyDetailsDataArray } from "./problemDetails.dummy";

const data = [
  {
    name: "أحمد علي",
    email: "ahmed.ali@example.com",
    gender: "ذكر",
    phone: "+966123456789",
    academicNumber: "12345678",
    createdAt: "2023-01-15",
    schedule: "الأحد إلى الخميس 8 صباحًا - 4 مساءً",
    academicFile: "كلية الهندسة",
  },
  {
    name: "فاطمة محمد",
    email: "fatima.mohammed@example.com",
    gender: "أنثى",
    phone: "+966987654321",
    academicNumber: "23456789",
    createdAt: "2023-02-20",
    schedule: "السبت إلى الأربعاء 9 صباحًا - 5 مساءً",
    academicFile: "كلية الطب",
  },
  {
    name: "يوسف خالد",
    email: "yousef.khaled@example.com",
    gender: "ذكر",
    phone: "+966456789123",
    academicNumber: "34567890",
    createdAt: "2023-03-10",
    schedule: "الأحد إلى الخميس 7 صباحًا - 3 مساءً",
    academicFile: "كلية العلوم",
  },
  {
    name: "ليلى عبد الله",
    email: "leila.abdullah@example.com",
    gender: "أنثى",
    phone: "+966321654987",
    academicNumber: "45678901",
    createdAt: "2023-04-15",
    schedule: "السبت إلى الخميس 10 صباحًا - 6 مساءً",
    academicFile: "كلية الآداب",
  },
  {
    name: "سالم سعيد",
    email: "salem.saeed@example.com",
    gender: "ذكر",
    phone: "+966654789321",
    academicNumber: "56789012",
    createdAt: "2023-05-01",
    schedule: "الأحد إلى الخميس 9 صباحًا - 5 مساءً",
    academicFile: "كلية الاقتصاد",
  },
  {
    name: "منى حسن",
    email: "mona.hassan@example.com",
    gender: "أنثى",
    phone: "+966789123456",
    academicNumber: "67890123",
    createdAt: "2023-06-10",
    schedule: "السبت إلى الأربعاء 8 صباحًا - 4 مساءً",
    academicFile: "كلية الفنون",
  },
  {
    name: "خالد ناصر",
    email: "khalid.nasser@example.com",
    gender: "ذكر",
    phone: "+966123987654",
    academicNumber: "78901234",
    createdAt: "2023-07-12",
    schedule: "الأحد إلى الخميس 7 صباحًا - 3 مساءً",
    academicFile: "كلية الحاسبات",
  },
  {
    name: "سارة عبد الرحمن",
    email: "sara.abdrahman@example.com",
    gender: "أنثى",
    phone: "+966789654123",
    academicNumber: "89012345",
    createdAt: "2023-08-18",
    schedule: "السبت إلى الأربعاء 10 صباحًا - 6 مساءً",
    academicFile: "كلية التربية",
  },
  {
    name: "عماد حسين",
    email: "emad.hussein@example.com",
    gender: "ذكر",
    phone: "+966654123789",
    academicNumber: "90123456",
    createdAt: "2023-09-25",
    schedule: "الأحد إلى الخميس 8 صباحًا - 4 مساءً",
    academicFile: "كلية الإعلام",
  },
  {
    name: "نور أحمد",
    email: "noor.ahmed@example.com",
    gender: "أنثى",
    phone: "+966321789654",
    academicNumber: "01234567",
    createdAt: "2023-10-02",
    schedule: "السبت إلى الخميس 9 صباحًا - 5 مساءً",
    academicFile: "كلية الصيدلة",
  },
  {
    name: "ياسر سامي",
    email: "yasser.sami@example.com",
    gender: "ذكر",
    phone: "+966987123654",
    academicNumber: "13579135",
    createdAt: "2023-09-11",
    schedule: "الأحد إلى الخميس 10 صباحًا - 6 مساءً",
    academicFile: "كلية العلوم التطبيقية",
  },
  {
    name: "هدى جمال",
    email: "hoda.jamal@example.com",
    gender: "أنثى",
    phone: "+966789321654",
    academicNumber: "13568001",
    createdAt: "2023-07-25",
    schedule: "السبت إلى الأربعاء 9 صباحًا - 5 مساءً",
    academicFile: "كلية القانون",
  },
  {
    name: "بدر علي",
    email: "bader.ali@example.com",
    gender: "ذكر",
    phone: "+966654987321",
    academicNumber: "13756332",
    createdAt: "2023-06-10",
    schedule: "الأحد إلى الخميس 8 صباحًا - 4 مساءً",
    academicFile: "كلية الرياضيات",
  },
  {
    name: "هديل مروان",
    email: "hadeel.marwan@example.com",
    gender: "أنثى",
    phone: "+966321456789",
    academicNumber: "13973212",
    createdAt: "2023-05-20",
    schedule: "السبت إلى الأربعاء 9 صباحًا - 5 مساءً",
    academicFile: "كلية العلوم الاجتماعية",
  },
  {
    name: "صالح فهد",
    email: "saleh.fahd@example.com",
    gender: "ذكر",
    phone: "+966789456123",
    academicNumber: "14567321",
    createdAt: "2023-04-25",
    schedule: "الأحد إلى الخميس 7 صباحًا - 3 مساءً",
    academicFile: "كلية الهندسة المعمارية",
  },
  {
    name: "ريم حمد",
    email: "reem.hamad@example.com",
    gender: "أنثى",
    phone: "+966123654987",
    academicNumber: "15673924",
    createdAt: "2023-03-30",
    schedule: "السبت إلى الخميس 10 صباحًا - 6 مساءً",
    academicFile: "كلية الآثار",
  },
  {
    name: "طارق فيصل",
    email: "tariq.faisal@example.com",
    gender: "ذكر",
    phone: "+966987654321",
    academicNumber: "16793245",
    createdAt: "2023-02-18",
    schedule: "الأحد إلى الخميس 9 صباحًا - 5 مساءً",
    academicFile: "كلية الهندسة الكيميائية",
  },
  {
    name: "نوال سعد",
    email: "nawal.saad@example.com",
    gender: "أنثى",
    phone: "+966789123654",
    academicNumber: "17892456",
    createdAt: "2023-01-12",
    schedule: "السبت إلى الأربعاء 8 صباحًا - 4 مساءً",
    academicFile: "كلية الترجمة",
  },
  {
    name: "عمر محمود",
    email: "omar.mahmoud@example.com",
    gender: "ذكر",
    phone: "+966456789123",
    academicNumber: "18975623",
    createdAt: "2023-05-15",
    schedule: "الأحد إلى الخميس 7 صباحًا - 3 مساءً",
    academicFile: "كلية الفيزياء",
  },
  {
    name: "رنا خالد",
    email: "rana.khaled@example.com",
    gender: "أنثى",
    phone: "+966321987654",
    academicNumber: "19023756",
    createdAt: "2023-07-23",
    schedule: "السبت إلى الخميس 9 صباحًا - 5 مساءً",
    academicFile: "كلية التصميم",
  },
];

export const dummyData = data.map((d) => {
  const problemType = problemsOptions.map((po) => po.label)[
    Math.floor(Math.random() * 5) + 1
  ];
  const department = departmentsOptions.map((po) => po.department)[
    Math.floor(Math.random() * 5)
  ];

  return {
    ...d,
    problemType,
    department,
    ...problemDetails(problemType),
  };
});

function problemDetails(problemType) {
  return dummyDetailsDataArray.find((d) => d.problemType === problemType);
}

import { Box, Button } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import LogoutIcon from "@mui/icons-material/Logout";

import { AppContext } from "../Context/App.context";
import PreviousProblemsBtn from "./PreviousProblemsBtn";
import ProfileSettingsBtn from "./ProfileSettingsBtn";

export default function AdminParentPage() {
  const { user, setUser } = React.useContext(AppContext);
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem("token");
    setUser({ role: "visitor" });
    navigate("/");
  };

  return (
    <Box sx={{ width: "90%", m: "auto", pb: 5 }}>
      <Outlet />

      <Box
        sx={{
          p: 2,
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 4,
        }}
      >
        {user.role === "student" && <ProfileSettingsBtn />}
        {user.role === "student" && <PreviousProblemsBtn />}

        {user.role !== "visitor" && (
          <Button
            onClick={logoutUser}
            variant="contained"
            startIcon={<LogoutIcon />}
            sx={{ pr: 1, m: 0.5 }}
          >
            <span style={{ marginRight: "10px" }}>تسجيل الخروج</span>
          </Button>
        )}
      </Box>
    </Box>
  );
}

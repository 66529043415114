import StorageIcon from "@mui/icons-material/Storage";
import CalculateIcon from "@mui/icons-material/Calculate";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import TimelineIcon from "@mui/icons-material/Timeline";

import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import SubjectIcon from "@mui/icons-material/Subject";

export const departmentsOptions = [
  {
    label: "نظم المعلومات الإدارية",
    icon: <StorageIcon />,
    number: "0677",
    department: "MIS",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D8%A7%D9%84%D8%AE%D8%B7%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AA%D9%8A%20%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF%20-%D9%82%D8%B3%D9%85%20%D9%86%D8%B8%D9%85%20%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA.pdf",
  },

  {
    label: "المالية",
    icon: <CalculateIcon />,
    number: "0675",
    department: "Financial",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D8%A7%D9%84%D9%85%D8%A7%D9%84%D9%8A%D8%A9%20-%20%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF.pdf",
  },

  {
    label: "إدارة الأعمال",
    icon: <ContactEmergencyIcon />,
    number: "0673",
    department: "Management",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF-%20%D8%A5%D8%AF%D8%A7%D8%B1%D8%A9%20%D8%A7%D9%84%D8%A3%D8%B9%D9%85%D8%A7%D9%84.pdf",
  },

  {
    label: "سلاسل الإمداد",
    icon: <ContactEmergencyIcon />,
    number: "0673",
    department: "Management",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D8%B3%D9%84%D8%A7%D8%B3%D9%84%20%D8%A7%D9%84%D8%A5%D9%85%D8%AF%D8%A7%D8%AF%20-%20%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF.pdf",
  },

  {
    label: "المحاسبة",
    icon: <CalculateIcon />,
    number: "0674",
    department: "Accounting",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D9%85%D8%AD%D8%A7%D8%B3%D8%A8%D8%A9%20%20-%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF.pdf",
  },

  {
    label: "الأساليب الكمية",
    icon: <StackedBarChartIcon />,
    number: "0676",
    department: "QM",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D9%85%D8%AE%D8%A7%D8%B7%D8%B1%20%D9%88%D8%AA%D8%A3%D9%85%D9%8A%D9%86%20-%D8%AA%D9%85%20%D8%A7%D9%84%D8%A7%D8%B9%D8%AA%D9%85%D8%A7%D8%AF.pdf",
  },

  {
    label: "الأقتصاد",
    icon: <TimelineIcon />,
    number: "0678",
    department: "Economic",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/Documents/%D8%A7%D9%84%D8%AE%D8%B7%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9%20%D8%A8%D8%B1%D8%A7%D9%85%D8%AC%20%D8%A7%D9%84%D8%A8%D9%83%D8%A7%D9%84%D9%88%D8%B1%D9%8A%D9%88%D8%B3%202024/%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF%D9%8A%D8%A7%D8%AA%20%D8%A7%D9%84%D8%A7%D8%B9%D9%85%D8%A7%D9%84%20-%20%D8%A7%D9%84%D8%AE%D8%B7%D8%A9%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%D8%A9.pdf",
  },
  {
    label: "آخرى",
    icon: <SubjectIcon />,
    number: "",
    department: "Others",
    link: "https://www.kfu.edu.sa/ar/Colleges/business-administration/CollegeDepartments/Pages/3301-10.aspx",
  },
];

export const problemsOptions = [
  { label: "الكل", icon: <CalendarViewDayIcon /> },
  { label: "شعبة ممتلئة (غير خريجين)" },
  { label: "شعبة ممتلئة (خريجين)" },
  { label: "القيود" },
  { label: "طلب تجاوز متطلب سابق (للخريجين)" },
  { label: "طلب تجاوز عدد ساعات (للخريجين)" },
];

export const managerSelections = [
  {
    label: "التخصص",
    value: "department",
    options: departmentsOptions,
  },
  {
    label: "المشكلة",
    value: "problem",
    options: problemsOptions,
  },
];

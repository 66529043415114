import React from "react";
import { Box, Button, Divider, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to show fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an external service if needed
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // **Fallback UI**
      return (
        <Box sx={{ textAlign: "center", p: 5 }}>
          <Paper sx={{ p: 4, display: "inline-block" }}>
            <Box>
              <ErrorOutlineIcon sx={{ fontSize: 100, color: "red" }} />
            </Box>
            <p>حدث خطأ ما.. الرجاء تحديث الصفحة</p>

            <Divider sx={{ mt: 5, mb: 2 }}>أو</Divider>

            <Button
              variant="contained"
              onClick={() => {
                // window.location.reload();
                window.location.href = "/";
              }}
              startIcon={<HomeIcon />}
            >
              <span style={{ padding: "5px", fontWeight: "bold" }}>
                العودة إلى الصفحة الرئيسية
              </span>
            </Button>
          </Paper>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

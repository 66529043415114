import { Box, Typography } from "@mui/material";
import React from "react";

export default function LabeledInput({ children, label, sx }) {
  return (
    <Box sx={{ mb: 1.5, ...sx }}>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "600",
          color: "primary.oldMain",
          pl: 2,
        }}
      >
        {label} {" : "}
      </Typography>

      {children}
    </Box>
  );
}

export const getExportedData = (data) => {
  const exportData = data.map((row) => {
    return {
      "اسم الطالب": row.user.username,
      "البريد الإلكترونى": row.user.email,
      الجنس: row.user.gender,
      "رقم الجوال": row.user.phone,
      المستوى: row.level,
      "الرقم الأكاديمى": row.user.academicNumber,
      "نوع المشكلة": row.problemType,
      "تاريخ المشكلة": row.createdAt,
      "رابط الجدول الدراسى":
        process.env.REACT_APP_API + "/uploads/" + row.scheduleFile,

      "رابط السجل الأكاديمى":
        process.env.REACT_APP_API + "/uploads/" + row.scheduleFile,
      الحالة: row.state || "",
      "رسالة الحالة": row.stateMsg || "",
      "عدد الساعات المكتسبة": row.gainedHours || "",

      ...problemSubjectsCols(row),

      القيود: row.constraints?.join(" - "),

      "صورة من القيد":
        process.env.REACT_APP_API + "/uploads/" + row.constraintFile || "",

      "عدد الساعات المطلوبة فى التسجيل": row.requiredHours || "",
    };
  });

  return exportData;
};

function problemSubjectsCols(row) {
  let res = {};

  if (row.subjects?.[0]) {
    row.subjects.forEach((subject, i) => {
      res[
        `المقرر ${i + 1} (اسم - رقم - CRN - رمز الشعبة)`
      ] = `${subject.name} - ${subject.number} - ${subject.crn} - ${subject.sectionNumber}`;
    });
  }

  return res;
}

import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import RadioInput from "../../../Components/inputs/RadioInput";
import TextInput from "../../../Components/inputs/TextInput";

export default function SettingsForm({
  inputs,
  data,
  onChange,
  error,
  result,
  onSubmit,
  currentFormName,
  onChangeFormName,
  enableSubmit,
}) {
  return (
    <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 10px", p: 2 }}>
      {inputs.map((input) => {
        return input.name === "gender" ? (
          <RadioInput
            label={input.label}
            name="gender"
            value={data.gender}
            onChange={onChange}
            options={["ذكر", "أنثى"]}
            key={input.name}
          />
        ) : (
          <TextInput
            label={input.label}
            value={data[input.name]}
            name={input.name}
            onChange={onChange}
            key={input.name}
            type={input.name === "academicNumber" && "number"}
            disabled={input.name === "email" ? true : false}
          />
        );
      })}

      <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>
        {error}
      </Typography>

      <Typography
        sx={{ color: "primary.main", fontSize: "14px", textAlign: "center" }}
      >
        {result}
      </Typography>

      <Button
        onClick={onChangeFormName}
        sx={{ textDecoration: "underline", fontWeight: "600" }}
      >
        {currentFormName === "info"
          ? "أو تعديل كلمة المرور"
          : "أو تعديل البيانات العامة"}
      </Button>

      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        sx={{ mt: 1, fontSize: "18px" }}
        disabled={!enableSubmit}
      >
        {currentFormName === "info" ? "تعديل البيانات" : "تغيير كلمة المرور"}
      </Button>
      {!enableSubmit && (
        <Typography sx={{ fontSize: "14px", color: "#999" }}>
          يُفعل هذا الزر إذا قمت بتعديل بياناتك الشخصية.
        </Typography>
      )}
    </Paper>
  );
}

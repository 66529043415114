export const studentHeaderLines = [
  { color: "#C59A47", text: "عزيزي الطالب/عزيزتي الطالبة" },
  {
    color: "#C59A47",
    text: "يرجى تسجيل المشكلة التي تواجهها أثناء تسجيل المقررات الدراسية بعد اختيار التخصص التابع لك",
  },
  {
    color: "#0a6a79",
    text: "نحن هنا لمساعدتك، وسيتم التعامل مع مشكلتك في أسرع وقت.",
  },
  {
    color: "#000",
    text: "الشئون الأكاديمية– كلية إدارة الأعمال – جامعة الملك فيصل",
  },
];

export const problemSelectionPlaceholder = "--الرجاء تحديد المشكلة--";
export const levelSelectionPlaceholder = "--الرجاء تحديد المستوى--";
export const initialSubjects = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

let yourDate = new Date();

export const getInitialProblemData = ({ department }) => {
  return {
    problemType: problemSelectionPlaceholder,
    level: levelSelectionPlaceholder,
    department,

    createdAt: yourDate.toISOString().split("T")[0],
    isCorrectData: false,
    subjects: initialSubjects,
    constraints: [],
  };
};

import React from "react";
import { getPreviousProblems } from "./api";
import { Box, Grid2, Paper, Typography } from "@mui/material";
import { problemsComponentsArray } from "../../../Components/ProblemTypes/Components/ProblemsArray";

export default function PreviousProblemsPage() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const func = async () => {
      const problems = await getPreviousProblems();
      setData(problems);
    };
    func();
  }, []);
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4" sx={{ fontWeight: "600", mb: 2, color: "#555" }}>
        الشكاوى السابقة :
      </Typography>
      {!data[0] ? (
        <Paper
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            color: "text.secondary",
            p: 5,
            textAlign: "center",
          }}
        >
          لا يوجد شكاوى سابقة لك.. لإضافة شكوى أضغط "إضافة شكوى " فى الأسفل.
        </Paper>
      ) : (
        <Grid2 container spacing={2}>
          {data?.map((problem) => {
            return (
              <Grid2 size={{ xs: 12, lg: 7 }} key={problem.id}>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", mb: 1, color: "primary.main" }}
                  >
                    {problem.problemType}
                  </Typography>
                  {
                    problemsComponentsArray({ row: problem }).find(
                      (p) => p.type === problem.problemType
                    ).component
                  }

                  {problem.state ? (
                    <Paper
                      sx={{
                        p: 2,
                        ml: 2,
                        mb: 2,
                        fontWeight: "600",
                        maxWidth: "400px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: "600", color: "primary.main" }}
                      >
                        الحالة الحالية : {problem.state}
                      </Typography>
                      {problem.stateMsg && (
                        <Typography>الرسالة: {problem.stateMsg}</Typography>
                      )}
                    </Paper>
                  ) : (
                    <Paper
                      sx={{
                        p: 2,
                        ml: 2,
                        mb: 2,
                        fontWeight: "600",
                        maxWidth: "400px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: "600", color: "primary.main" }}
                      >
                        <p>الحالة الحالية :</p> تم تسجيل الشكوى و فى انتظار
                        الرد...
                      </Typography>
                      {problem.stateMsg && (
                        <Typography>الرسالة: {problem.stateMsg}</Typography>
                      )}
                    </Paper>
                  )}
                </Paper>
              </Grid2>
            );
          })}
        </Grid2>
      )}
    </Box>
  );
}

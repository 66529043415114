import { createBrowserRouter } from "react-router-dom";

import { adminRoutes } from "./adminRoutes";
import { visitingParentRoutes } from "./visitingParentRoutes";
import { studentRoutes } from "./studentRoutes";
import ErrorElement from "./ErrorElement";
import RedirectRegistration from "../Pages/LoginPage/RedirectRegistration";
import PostedRegistrationData from "../Pages/LoginPage/PostedRegistrationData";
import FpResetPassword from "../Pages/LoginPage/FpResetPassword";
import FpSendEmail from "../Pages/LoginPage/FpSendEmail";

const routes = (isAuthenticated) => {
  switch (isAuthenticated) {
    case "admin":
      return adminRoutes;

    case "student":
      return studentRoutes;

    case "visit":
      return visitingParentRoutes;

    default:
      return visitingParentRoutes;
  }
};

export const redirectRegistrationPath = "auth/register/:token";
export const postedRegistrationDataPath = "auth/post-register";
export const fpResetPasswordPath = "/auth/forget-password-reset";
export const fbSendEmailPath = "/auth/forget-password-send-email";

export const router = (user) =>
  createBrowserRouter([
    ...routes(user.role),

    {
      path: "*",
      element: <ErrorElement />,
    },
  ]);
// export const router = (user) => createBrowserRouter(routes("admin"));

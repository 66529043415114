import { createTheme, ThemeProvider } from "@mui/material";
import { AppContextProvider } from "./Context/App.context";
import AppRoute from "./routes";
import LogoAppBar from "./Components/LogoAppBar";
import ErrorBoundary from "./routes/BoundaryComponent";

function App() {
  const theme = createTheme({
    direction: "rtl",
    typography: {
      fontFamily: ["Open Sans", "Roboto"].join(","),
    },
    palette: {
      primary: {
        // main: "#00582E",
        oldMain: "#0a6a79",
        main: "#0a6a79",
        studentTabsBackground: "#f1f2f3",
      },
    },
  });

  window.onerror = function (message, source, lineno, colno, error) {
    console.error("Global error:", message, error);
    // Optionally show a user-friendly message
  };

  window.onunhandledrejection = function (event) {
    console.error("Unhandled promise rejection:", event.reason);
    // Optionally show a user-friendly message
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AppContextProvider>
          <div dir="rtl">
            <LogoAppBar />

            <AppRoute />
          </div>
        </AppContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import SideLabeledInput from "../../inputs/SideLabeledInput";

export default function HoursOrderSubDetails({ state, disabled, onChange }) {
  return (
    <>
      <SideLabeledInput
        label="عدد الساعات المكتسبة"
        name="gainedHours"
        value={state.gainedHours}
        disabled={disabled}
        type="number"
        onChange={onChange}
      />

      <SideLabeledInput
        label="عدد الساعات المطلوبة فى التسجيل"
        name="requiredHours"
        value={state.requiredHours}
        disabled={disabled}
        type="number"
        onChange={onChange}
      />
    </>
  );
}

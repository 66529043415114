import axios from "axios";

export const BASE_URL =
  process.env.REACT_APP_API || "https://problemsapi.reports.ltd";

export const mainUrlApi = axios.create({
  baseURL: BASE_URL,
});

const api = axios.create({
  baseURL: BASE_URL + "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to set the Authorization header
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Get the token each time before the request
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors or refreshing tokens
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, like refreshing the token
      // Add token refresh logic here if applicable
    }
    return Promise.reject(error);
  }
);

export default api;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

import { previousProblemsPath } from "../routes/studentRoutes";

export default function PreviousProblemsBtn() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClickPreviousProblems = () => {
    navigate(pathname === previousProblemsPath ? "/" : previousProblemsPath);
  };

  return (
    <Button
      onClick={onClickPreviousProblems}
      variant="contained"
      startIcon={
        pathname === previousProblemsPath ? (
          <ControlPointDuplicateIcon />
        ) : (
          <TextSnippetIcon />
        )
      }
      sx={{ pr: 1, m: 0.5 }}
    >
      <span style={{ marginRight: "10px" }}>
        الشكاوى السابقة
        {/* {pathname === previousProblemsPath ? "إضافة شكوى" : "الشكاوى السابقة"} */}
      </span>
    </Button>
  );
}

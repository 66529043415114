import { Box } from "@mui/material";
import React from "react";
import AdminProblemComponents from "../../../../Components/ProblemTypes/AdminProblemComponents";

export default function SubDetails({ row, setData, data }) {
  return (
    <Box sx={{ textAlign: "right" }}>
      <AdminProblemComponents row={row} setData={setData} data={data} />
    </Box>
  );
}

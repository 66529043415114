import React from "react";
import { updateProfilePasswordInputs } from "../../LoginPage/utils";
import api from "../../../api/axios";
import SettingsForm from "./SettingsForm";

export default function PasswordSettings({
  onChangeFormName,
  currentFormName,
}) {
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState("");
  const [result, setResult] = React.useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setResult("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    let hasError = false;

    // Validate inputs
    updateProfilePasswordInputs.forEach((input) => {
      if (!data[input.name]) {
        setError(`برجاء إدخال ${input.label}`);
        hasError = true;
      }
    });

    if (data.newPassword !== data.confirmNewPassword) {
      setError(
        " برجاء التأكد من تطابق كلمة السر الجديدة وإعادة كلمة السر الجديدة"
      );
      hasError = true;
    }

    // If there are any errors, stop further execution
    if (hasError) {
      return;
    }

    if (!error) {
      setResult("");

      try {
        const res = await api.patch("user/update-password", data);
        if (res?.data) {
          setResult("تم تعديل البيانات");
          setData(res?.data);
        } else {
          setError("حدث خطأ ما");
        }
      } catch (error) {
        setError(error?.response?.data?.message);
      }
    }
  };

  return (
    <SettingsForm
      inputs={updateProfilePasswordInputs}
      data={data}
      onChange={onChange}
      error={error}
      onSubmit={onSubmit}
      result={result}
      onChangeFormName={onChangeFormName}
      currentFormName={currentFormName}
      enableSubmit={true}
    />
  );
}

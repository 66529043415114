import React from "react";
import { updateProfileInputs } from "../../LoginPage/utils";
import SettingsForm from "./SettingsForm";
import api from "../../../api/axios";

export default function InfoSettings({ onChangeFormName, currentFormName }) {
  const [res, setRes] = React.useState({});
  const [data, setData] = React.useState({
    gender: "ذكر",
  });
  const [error, setError] = React.useState("");
  const [result, setResult] = React.useState("");
  const [enableSubmit, setEnableSubmit] = React.useState(false);

  React.useEffect(() => {
    const func = async () => {
      const res = await api.get("user/get-info");

      setData(res.data);
      setRes(res.data);
    };
    func();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setResult("");

    Object.keys(res).forEach((key) => {
      if (key === name) {
        if (res[key] !== value) {
          setEnableSubmit(true);
        } else {
          setEnableSubmit(false);
        }
      }
    });
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    let hasError = false;

    // Validate inputs
    updateProfileInputs.forEach((input) => {
      if (!data[input.name]) {
        setError(`برجاء إدخال ${input.label}`);
        hasError = true;
      }
    });

    // Validate academic number
    if (data.academicNumber.toString().length !== 9) {
      setError(
        "رجاء التأكد من إدخال الرقم الأكاديمى المكون من 9 أرقام بشكل صحيح."
      );
      hasError = true;
    }

    // If there are any errors, stop further execution
    if (hasError) {
      return;
    }

    setResult("");

    try {
      const res = await api.patch("user/update-info", data);
      if (res?.data) {
        setResult("تم تعديل البيانات");
        setData(res?.data);
      } else {
        setError("حدث خطأ ما");
      }
    } catch (error) {
      setError("حدث خطأ ما");
    }
  };

  return (
    <SettingsForm
      inputs={updateProfileInputs}
      data={data}
      onChange={onChange}
      error={error}
      onSubmit={onSubmit}
      result={result}
      onChangeFormName={onChangeFormName}
      currentFormName={currentFormName}
      enableSubmit={enableSubmit}
    />
  );
}

import { problemsOptions } from "../../../Components/CategoryManager/utils";

const options = problemsOptions.map((p) => p.label).slice(1);

const filledSectionSubDetailsData = {
  gainedHours: 4,
  subjects: [
    {
      name: "الرياضيات",
      crn: "1267",
      sectionNumber: "K2",
    },
    {
      name: "الكيمياء",
      crn: "3254",
      sectionNumber: "K2",
    },
    {
      name: "الحاسب",
      crn: "2864",
      sectionNumber: "K2",
    },
    {
      name: "البرمجة",
      crn: "2222",
      sectionNumber: "K2",
    },
  ],
};

const constraintsSubDetailsData = {
  constraints: [
    "القيود على مجال الدراسة",
    // "قيد قسم",
    // "قيود على الدفعة",
    // "قيود المرحلة",
  ],
  subject: {
    name: "الرياضيات",
    crn: "2759",
    sectionNumber: "H2",
  },
};

const oldOrderSubDetails = {
  gainedHours: 3,
  subjects: [
    {
      name: "الرياضيات",
      crn: "1267",
      sectionNumber: "K2",
    },
  ],
};

const hoursOrderSubDetails = {
  gainedHours: 4,
  requiredHours: 3,
};

export const dummyDetailsDataArray = [
  { problemType: options[0], problemDetails: filledSectionSubDetailsData },
  {
    problemType: options[1],
    problemDetails: filledSectionSubDetailsData,
  },
  {
    problemType: options[2],
    problemDetails: constraintsSubDetailsData,
  },
  {
    problemType: options[3],
    problemDetails: oldOrderSubDetails,
  },
  {
    problemType: options[4],
    problemDetails: hoursOrderSubDetails,
  },
];

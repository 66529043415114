import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React from "react";
import LabeledInput from "./LabeledInput";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const passwordNames = [
  "password",
  "confirmPassword",
  "oldPassword",
  "newPassword",
  "confirmNewPassword",
];

export default function TextInput({
  onChange,
  value,
  label,
  name,
  disabled,
  type,
  sx,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LabeledInput label={label}>
      {passwordNames.includes(name) ? (
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          hiddenlabel="true"
          fullWidth
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <TextField
          variant="outlined"
          hiddenlabel="true"
          size="small"
          fullWidth
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          type={type ? type : "text"}
          sx={sx}
        />
      )}
    </LabeledInput>
  );
}

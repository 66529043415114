import { Box, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import LabeledInput from "./LabeledInput";

export default function LabeledSelect({
  value,
  onChange,
  label,
  name,
  options,
  disabled,
  placeholder,
  size,
}) {
  return (
    <LabeledInput label={label}>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        size={size}
        disabled={disabled}
        // displayEmpty
        input={<OutlinedInput />}
        // inputProps={{ "aria-label": "Without label" }}
        sx={{
          width: "100%",
          fontWeight: "600",
          fontSize: "1.1rem",
          color: "#555",
        }}
        renderValue={(selected) => {
          return !value ? <Placeholder placeholder={placeholder} /> : selected;
        }}
      >
        <MenuItem disabled value={placeholder}>
          <Box
            sx={{
              width: "100%",
              textAlign: "right",
            }}
          >
            <Placeholder placeholder={placeholder} />
          </Box>
        </MenuItem>

        {options.map((opt) => {
          return (
            <MenuItem value={opt} key={opt}>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "right",
                }}
              >
                {opt}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </LabeledInput>
  );
}

const Placeholder = ({ placeholder }) => {
  return <em style={{ color: "#777", paddingRight: "10px" }}>{placeholder}</em>;
};

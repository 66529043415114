import { Box } from "@mui/material";
import React from "react";
import InfoSettings from "./InfoSettings";
import PasswordSettings from "./PasswordSettings";

export default function ProfileSettings() {
  const [currentFormName, setCurrentFormName] = React.useState("info");

  const onChangeFormName = () => {
    if (currentFormName === "info") {
      setCurrentFormName("password");
    } else {
      setCurrentFormName("info");
    }
  };

  return (
    <Box sx={{ mr: 3, mt: 5 }}>
      {currentFormName === "info" ? (
        <InfoSettings
          currentFormName={currentFormName}
          onChangeFormName={onChangeFormName}
        />
      ) : (
        <PasswordSettings
          currentFormName={currentFormName}
          onChangeFormName={onChangeFormName}
        />
      )}
    </Box>
  );
}

import VisitingParentPage from "../Pages/VisitingParentPage";
import LoginPage from "../Pages/LoginPage";
import RedirectRegistration from "../Pages/LoginPage/RedirectRegistration";
import PostedRegistrationData from "../Pages/LoginPage/PostedRegistrationData";
import FpResetPassword from "../Pages/LoginPage/FpResetPassword";
import FpSendEmail from "../Pages/LoginPage/FpSendEmail";

export const fpResetPasswordPath = "/auth/forget-password-reset";
export const fbSendEmailPath = "/auth/forget-password-send-email";

export const visitingParentRoutes = [
  {
    path: "/",
    element: <VisitingParentPage />,

    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "auth/register/:token",
        element: <RedirectRegistration />,
      },
      {
        path: "auth/post-register",
        element: <PostedRegistrationData />,
      },
      {
        path: fbSendEmailPath,
        element: <FpSendEmail />,
      },
      {
        path: fpResetPasswordPath + "/:token",
        element: <FpResetPassword />,
      },
    ],
  },
];

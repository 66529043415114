import { Box, Button, Divider, Paper } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";

export default function ErrorElement() {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: "center", p: 5 }}>
      <Paper sx={{ p: 4, display: "inline-block" }}>
        <Box>
          <ErrorOutlineIcon sx={{ fontSize: 100, color: "red" }} />
        </Box>
        <p>حدث خطأ ما.. الرجاء تحديث الصفحة</p>

        <Divider sx={{ mt: 5, mb: 2 }}>أو</Divider>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
          startIcon={<HomeIcon />}
        >
          <span style={{ padding: "5px", fontWeight: "bold" }}>
            العودة إلى الصفحة الرئيسية
          </span>
        </Button>
      </Paper>
    </Box>
  );
}

import React from "react";
import SideLabeledInput from "../../inputs/SideLabeledInput";
import SubjectRow from "./Components/SubjectRow";

export default function FilledSectionSubDetailsInput({
  state,
  onChange,
  onChangeSubject,
  subjectsList,
}) {
  return (
    <>
      <SideLabeledInput
        label="عدد الساعات المكتسبة"
        value={state.gainedHours}
        name="gainedHours"
        onChange={onChange}
        type="number"
        autoComplete="off"
      />

      {subjectsList.map((s, i) => {
        return (
          <SubjectRow
            key={s.id}
            subject={s}
            index={i}
            onChangeSubject={onChangeSubject}
            state={state}
          />
        );
      })}
    </>
  );
}

import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { AppContext } from "../Context/App.context";
import { Box } from "@mui/material";

export default function AppRoute() {
  const { user } = React.useContext(AppContext);

  return (
    <Box sx={{ position: "relative", pb: 5 }}>
      <RouterProvider router={router(user)} />
    </Box>
  );
}

import { problemsOptions } from "../../CategoryManager/utils";
import ConstraintsSubDetails from "./ConstraintsSubDetails";
import ConstraintsSubDetailsInput from "./ConstraintsSubDetailsInput";
import FilledSectionSubDetails from "./FilledSectionSubDetails";
import FilledSectionSubDetailsInput from "./FilledSectionSubDetailsInput";
import HoursOrderSubDetails from "./HoursOrderSubDetails";
import OldOrderSubDetails from "./OldOrderSubDetails";

export const problemsComponentsArray = ({
  row,
  state,
  onChange,
  onChangeSubject,
  subjectsList,
  onChangeConstraints,
  onSelectFile,
}) => {
  const problems = problemsOptions.map((p) => p.label);

  return [
    {
      type: problems[1],

      component: <FilledSectionSubDetails problem={row} />,
      form: (
        <FilledSectionSubDetailsInput
          state={state}
          onChange={onChange}
          onChangeSubject={onChangeSubject}
          subjectsList={subjectsList}
        />
      ),
    },
    {
      type: problems[2],

      component: <FilledSectionSubDetails problem={row} />,
      form: (
        <FilledSectionSubDetailsInput
          state={state}
          onChange={onChange}
          onChangeSubject={onChangeSubject}
          subjectsList={subjectsList}
        />
      ),
    },
    {
      type: problems[3],

      component: <ConstraintsSubDetails problem={row} />,
      form: (
        <ConstraintsSubDetailsInput
          state={state}
          onChange={onChange}
          onChangeSubject={onChangeSubject}
          subjectsList={[subjectsList?.[0]]}
          onChangeConstraints={onChangeConstraints}
          onSelectFile={onSelectFile}
        />
      ),
    },
    {
      type: problems[4],

      component: <OldOrderSubDetails problem={row} />,
      form: (
        <FilledSectionSubDetailsInput
          state={state}
          onChange={onChange}
          onChangeSubject={onChangeSubject}
          subjectsList={[subjectsList?.[0]]}
        />
      ),
    },
    {
      type: problems[5],
      component: <HoursOrderSubDetails state={row} disabled />,
      form: <HoursOrderSubDetails state={state} onChange={onChange} />,
    },
  ];
};

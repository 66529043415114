import { Box, Button, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PostedRegistrationData() {
  const navigate = useNavigate();

  return (
    <Box>
      <Paper sx={{ p: 5, mt: 10, textAlign: "center", mx: { md: 7, xs: 2 } }}>
        <h1 style={{ textAlign: "center", color: "#333" }}>
          مرحبا بك فى منصة الشكاوى
        </h1>
        <p style={{ textAlign: "center", color: "green" }}>
          تم تسجيل حسابك بالفعل.. الرجاء تأكيد البريد الإلكترونى من خلال الرسالة
          فى صندوق البريد الإلكترونى
        </p>

        <p style={{ textAlign: "center", color: "#333" }}>
          إذا لم تصلك الرسالة فى البريد الوارد.. الرجاء تفقد Spam فى البريد
          الإلكترونى الخاص بك
        </p>

        <p style={{ textAlign: "center", color: "#333", paddingTop: "30px" }}>
          إذا تم تأكيد الحساب بالفعل.. اضغط على الزر أدناه{" "}
        </p>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          الدخول لحسابى
        </Button>
      </Paper>
    </Box>
  );
}

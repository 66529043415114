import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import LabeledInput from "./LabeledInput";

export default function RadioInput({
  onChange,
  value,
  label,
  name,
  options,
  disabled,
}) {
  return (
    <LabeledInput label={label}>
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        {options.map((opt) => {
          return (
            <FormControlLabel
              disabled={disabled}
              value={opt}
              control={<Radio />}
              label={opt}
              key={opt}
              sx={{ pl: 2 }}
            />
          );
        })}
      </RadioGroup>
    </LabeledInput>
  );
}

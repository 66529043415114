import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";

import DescriptionIcon from "@mui/icons-material/Description";

import SubjectRow from "./Components/SubjectRow";
import { downloadFile } from "../../../api/files";
import { BASE_URL } from "../../../api/axios";

export default function ConstraintsSubDetails({ problem }) {
  return (
    <>
      <Box sx={{ width: "400px", mb: 4 }}>
        {problem?.constraints?.map((c) => {
          return (
            <Box
              sx={{
                flexGrow: 1,

                display: "inline-block",
                color: "primary.main",
                fontSize: "14px",
                "& span": {
                  p: 0.3,
                },
              }}
              key={c}
            >
              <FormControlLabel
                control={<Checkbox checked disabled />}
                label={c}
                sx={{ color: "primary.main" }}
              />
            </Box>
          );
        })}
      </Box>

      <SubjectRow subject={problem.subjects[0]} index={0} disabled />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "18px",
          color: "primary.main",
          py: 2,
          px: 2,
        }}
      >
        صورة القيد :{" "}
        <Tooltip title="تحميل صورة القيد" placement="top">
          {/* <IconButton
            onClick={() => {
              downloadFile(problem.constraintFile);
            }}
          >
            <DescriptionIcon />
          </IconButton> */}
          <IconButton sx={{ m: "auto" }}>
            <a
              href={BASE_URL + "/uploads/" + problem.constraintFile}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#777" }}
            >
              <DescriptionIcon color="text.secondary" />
            </a>
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}

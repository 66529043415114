import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../Components/inputs/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/axios";
import { AppContext } from "../../Context/App.context";

export default function FpResetPassword() {
  const { token } = useParams();
  const { setUser, setCurrentUser } = React.useContext(AppContext);

  const [error, setError] = React.useState("");
  const [data, setData] = React.useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!data.newPassword || !data.confirmNewPassword) {
      setError("برجاء استكمال البيانات");
    }
    if (data.newPassword !== data.confirmNewPassword) {
      setError("كلمة المرور غير متطابقة");
    }
    if (
      data.newPassword &&
      data.confirmNewPassword &&
      data.newPassword === data.confirmNewPassword
    ) {
      localStorage.setItem("token", token);
      setUser({ role: "visitor" });
      const res = await api.post("auth/fpResetPassword", data);
      localStorage.removeItem("token");

      setCurrentUser(res.data.accessToken);
      navigate("/");
      console.log(res);

      if (res?.data.message) {
        setError(res.data.message);
      }
    }
  };

  return (
    <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 20px auto", p: 2 }}>
      <TextInput
        label="كلمة المرور الجديدة"
        value={data.newPassword}
        name="newPassword"
        onChange={onChange}
      />

      <TextInput
        label="تأكيد كلمة المرور الجديدة"
        value={data.confirmNewPassword}
        name="confirmNewPassword"
        onChange={onChange}
      />

      <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>
        {error}
      </Typography>

      <Button
        onClick={() => {
          navigate("/");
        }}
        sx={{ textDecoration: "underline", fontWeight: "600" }}
      >
        ليس عندى حساب, إنشاء حساب جديد ؟
      </Button>

      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        sx={{ mt: 1, fontSize: "18px" }}
      >
        تجديد كلمة المرور
      </Button>
    </Paper>
  );
}

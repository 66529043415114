import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { problemsComponentsArray } from "./Components/ProblemsArray";
import axios from "../../api/axios";
import CancelIcon from "@mui/icons-material/Cancel";
import TextInput from "../inputs/TextInput";

const stateButtons = [
  { label: "قبول مبدئي" },
  { label: "قبول نهائي" },
  { label: "رفض مبدئي" },
  { label: "رفض نهائي" },
];
export default function AdminProblemComponents({ row, setData, data }) {
  const [currentState, setCurrentState] = React.useState({
    state: "",
    stateMsg: "",
    submitted: false,
  });

  const [error, setError] = React.useState("");

  const onMsgChange = (e) => {
    setCurrentState({ ...currentState, stateMsg: e.target.value });
  };

  const onCancelAddingState = () => {
    setCurrentState({ state: "", stateMsg: "" });
  };

  const onSubmit = async () => {
    try {
      const res = await axios.patch("problem/" + row.id, { ...currentState });
      if (res?.data?.id) {
        const updatedData = data.map((r) => {
          if (r.id === row.id) {
            return { ...r, state: res.data.state, stateMsg: res.data.stateMsg };
          } else {
            return r;
          }
        });

        setData(updatedData);
        setCurrentState({ submitted: true });
      }
    } catch (error) {
      console.error(error);
      setError("حدث خطأ في عملية إضافة الحالة.. الرجاء تحديث الصفحة.");
    }
  };
  console.log(row);

  return (
    <Box>
      {row.department === "آخرى" && (
        <TextInput
          label="أسم التخصص"
          disabled
          value={row.otherDepartmentName}
          sx={{ width: "50%" }}
        />
      )}
      <Typography
        variant="h6"
        sx={{ fontWeight: "600", mb: 1, color: "primary.main" }}
      >
        {row.problemType}
      </Typography>

      <Box sx={{ width: "50%" }}>
        {
          problemsComponentsArray({ row }).find(
            (p) => p.type === row.problemType
          ).component
        }
      </Box>
      <Box sx={{ pt: 2, float: "left" }}>
        {row.state && (
          <>
            <Paper
              sx={{ p: 2, ml: 2, mb: 2, fontWeight: "600", maxWidth: "400px" }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 1, fontWeight: "600", color: "primary.main" }}
              >
                الحالة الحالية : {row.state}
              </Typography>
              {row.stateMsg && <Typography>الرسالة: {row.stateMsg}</Typography>}
            </Paper>
            <Divider sx={{ mb: 2 }}>تغيير الحالة</Divider>
          </>
        )}
        <Box>
          {stateButtons.map((btn) => {
            return (
              <Button
                onClick={() => {
                  setCurrentState({ state: btn.label, submitted: false });
                }}
                variant="contained"
                sx={{ ml: 2, fontWeight: "600" }}
              >
                {btn.label}
              </Button>
            );
          })}
        </Box>
        <Box sx={{ pl: 2 }}>
          {currentState.state && (
            <Paper sx={{ mt: 2, p: 2, position: "relative" }}>
              <IconButton
                onClick={onCancelAddingState}
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  color: "primary.main",
                }}
              >
                <CancelIcon />
              </IconButton>
              <Typography sx={{ color: "primary.main" }}>
                الحالة : {currentState.state}
              </Typography>
              <Box sx={{ pt: 1, pl: 2 }}>
                <TextField
                  multiline
                  fullWidth
                  value={currentState.stateMsg}
                  onChange={onMsgChange}
                  maxRows={7}
                  placeholder="إرسال رسالة نصية"
                />
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  موافقة
                </Button>
                <Typography sx={{ textAlign: "center", color: "red" }}>
                  {error}
                </Typography>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
}

import React from "react";
import GainedHoursInput from "./Components/GainedHoursInput";
import SubjectRow from "./Components/SubjectRow";

export default function OldOrderSubDetails({ problem }) {
  return (
    <>
      <GainedHoursInput
        label="عدد الساعات المكتسبة"
        value={problem.gainedHours}
      />

      {problem.subjects.map((subject, index) => {
        return (
          <SubjectRow
            index={index}
            subject={subject}
            key={subject.subject + index}
            disabled
          />
        );
      })}
    </>
  );
}
